import React, { useContext, useMemo } from 'react';

import ReportGijgoGrid from './../../internal/ReportsScreens/GijgoForReportsScreen/index';
import { Grid, Typography } from '@material-ui/core';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
// import EditBranchScreen from './editBranch';
import { useState } from 'react';
import DialogComp from './../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import ConfirmationModal from '../../../Core/Controls/Dialog/ConfirmationModal';
import { postReleaseJobLock_Save } from '../../../Core/Service/branch-screen-service';
import AppContext from '../../../App/AppContext';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const baseColumns = [
    { field: 'action', sortable: false, title: ``, width: 70, filterable: false },
    { field: 'wipNumber', sortable: false, title: `WIP No`, width: 100 },
    {
        field: 'registration',
        sortable: false,
        title: `Registration`,
        width: 150
    },
    { field: 'lockUser', sortable: false, title: `Locked By`, width: 130 },
    {
        field: 'lockTime',
        sortable: false,
        title: `Lock Time`,
        width: 160
    },
    { field: 'branch', sortable: false, title: `Branch`, width: 150 },
    { field: 'customerName', sortable: false, title: `Customer Name` }
];

const baseUrl = 'JobLocks/JobLocks_Get';

const crumbs = (path) => [
    { name: 'Home', path: '' },
    { name: 'Locked Jobs', active: true }
];

const JobLockListScreen = () => {
    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        showWarning: false,
        selectedJob: {}
    });

    const hideConfirmationModal = (res) => {
        let newSt = {};
        newSt.showWarning = false;
        newSt.selectedJob = {};
        if (res) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    const handleEdit = (val) => {
        setState((st) => ({
            ...st,
            showWarning: true,
            selectedJob: val
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" justifyContent="center">
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => handleEdit(record)}
                >
                    <LockOpenIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');

        if (colAction) {
            colAction.renderer = editButton;
        }
        return col;
    }, []);

    const submitFunction = async () => {
        console.log(state.selectedJob.jobLockHeaderID, 'state.selectedJob.jobLockHeaderID');
        let res = await postReleaseJobLock_Save(state.selectedJob.jobLockHeaderID);
        if (res?.data?.success) {
            hideConfirmationModal(true);
            showToast(res.data.message);
        } else {
            showToast(res?.data?.message);
        }
    };

    return (
        <div className="report-screen-container">
            {state.showWarning ? (
                <DialogComp title="Warning" onClose={() => hideConfirmationModal(false)} maxWidth="sm">
                    <ConfirmationModal
                        message={`Release lock for job ${state.selectedJob.wipNumber}`}
                        handleSubmit={submitFunction}
                        handleCancel={() => hideConfirmationModal(false)}
                    />
                </DialogComp>
            ) : null}
            <BreadCrumbs crumbs={crumbs()} />
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    records: res?.data?.list.map((p) => ({ ...p }))
                })}
                columns={column}
                getUrl={baseUrl}
                isReload={state.isReload}
                isShowTotal={false}
            />
        </div>
    );
};

export default JobLockListScreen;
